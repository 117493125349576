import ludo from '../../assets/ludo.jpg'
import NavBar from '../../components/navbar/navbar'
import About from '../about/about'
import Contact from '../contact/contact'
import Projects from '../projects/projects'
import './home.css'

export default function Home() {
    return (
        <>
            <Poster heading={'Full stack developer'} />
            
            <About />

            <Projects  />

            <Contact />
        </>
    )
}



function Poster({ heading }) {
    return (
        <div id='top' className='main-poster'>
            <img className='mainImage' src={ludo}></img>
            <h1 className='centrePiece'>{heading}</h1>
        </div>
    )
}

