import { ScrollProvider } from 'react-scroll'
import { Helmet } from 'react-helmet';
import Home from './pages/home/home';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import { NavBar } from './components/navbar/navbar';


function App() {
  return (
    <div>
      <Helmet>
        <title>Ludo</title>
        <meta name="Ludo's page" content="Profile of an aspiring Full stacl dev" />
        <meta name="keywords" content="full stack, code, website, react" />
      </Helmet>
      
        <Home />
      
    </div>
  );
}

export default App;