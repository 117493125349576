import './contact.css'

export default function Contact() {
    return (
      <div id='contact' className='contact'>
        <h1>Contact</h1>
        
        <Item value='e-mail' text={'ludobourneville@gmail.com'} />

      </div>
    )
  }
  
function Item({ value, text }) {
  return (
    <div id='contact' className='contact-item'>
      <span>{value}: </span>
      <span>{text}</span>
    </div>
  )
}