import './about.css'

export default function About() {
    return (
      <div id='about' className='about'>
        <h1>About</h1>
        <p>
          I am a recent Graduate of a Diploma in Software Development from Whitecliffe College. 
          Seeking a proffessional role in Full-stack or Back-end development.
        </p>
        <p>
          Confident in Python, Django, React, JavaScript, HTML, CSS, SQL & NoSQL databases 
        </p>
      </div>
    )
  }
