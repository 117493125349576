import { Link, animateScroll as scroll } from "react-scroll";

import './navbar.css'
import { Component } from 'react'

export default class NavBar extends Component {
    render() {
      return (
        <nav>
          <ul>
            <li>
              <Link
                activeClass="active"
                to="top"
                spy={true}
                smooth={true}
                offset={0}
                duration={200}
                >Home</Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={200}
                >about</Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="projects"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                >projects</Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="projects"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                >contact</Link>
            </li>

          </ul>
        </nav>
      )
    } 
  }
  
  const scrollToTop = () => {
    scroll.scrollToTop()
  }
  
  const scrollToBottom = () => {
    scroll.scrollToBottom()
  }
  
  const scrollToElement = () => {
    scroll.scrollTo()
  }