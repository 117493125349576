import './projects.css'

export default function Projects() {
    return (
        <div id='projects' className='projects'>
            <h1>Projects:</h1>

            <ul>
                <li>
                    <a href='https://www.kauripointconstruction.co.nz/home'>
                        Kauri Point Construction
                    </a>
                </li>
                <li>
                    <a href='https://www.localfibre.co.nz'>
                        Local Fibre
                    </a>
                </li>
            </ul>

        </div>
    )
}